import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import ProjectText from './components/ProjectText/ProjectText'
import MainSvg from './components/MainSvg/MainSvg'
import Bassinet from './components/Bassinet/Bassinet'

import * as styles from './project.module.scss'

const Seasons = () => {
  const [season, setSeason] = useState(null)
  const [showIntro, setShowIntro] = useState(true)

  useEffect(() => {
    if (!showIntro) {
      setSeason('FallSeason')
      setTimeout(() => {
        setSeason('WinterSeason')
      }, 13000)
      setTimeout(() => {
        setSeason('SpringSeason')
      }, 26000)
      setTimeout(() => {
        setSeason(null)
        setShowIntro(true)
      }, 65000)
    }
    return () => {
      setSeason(null)
    }
  }, [showIntro])
  
  
  return (
    <div className={clsx(
      styles.container,
      {
        [styles.FallSeason]: season === 'FallSeason'
      },
      {
        [styles.WinterSeason]: season === 'WinterSeason'
      },
      {
        [styles.SpringSeason]: season === 'SpringSeason'
      }
    )}>
      { showIntro ? (
        <div className={styles.intro}>
          <div className={styles.introText} onClick={() => setShowIntro(false)}>
            <p>The Life of a Project</p>
            <div className={styles.start}>start</div>
          </div>
          <div className={styles.requirements}>(I am best viewed on desktop, because my developer got lazy.)</div>
        </div>      
      ) : season ? (
          <>
            <ProjectText season={season} />
            <MainSvg season={season} />
            <div className={styles.BassinetContainer}>
              <Bassinet season={season}/>
            </div>
          </>
        ) : (
          <div className={styles.fin}>
            <div className={styles.finText}>fin</div>
          </div>
        )}
    </div>
  )
}

export default Seasons