import React from 'react'
import clsx from 'clsx'
import * as styles from './Bassinet.module.scss'

const Bassinet = ({
  season
}) => {
	return (
    <div className={clsx(
      styles.Bassinet,
      {
        [styles.reveal]: season === 'SpringSeason'
      }
    )}>
      <>
        <div className={styles.launchingText}>Launching March 2022</div>
        <div className={styles.bassinetSvg}>
          <svg version="1.1" id="Layer_1" viewBox="0 0 1046.3 1125.2">
            <path className={styles.st0} d="M523,915"/>
            <g>
              
                <rect x="205.4" y="749" transform="matrix(0.8907 0.4545 -0.4545 0.8907 420.0285 -18.2149)" className={styles.st0} width="85" height="231"/>
              <rect x="751.4" y="749" transform="matrix(0.866 -0.5 0.5 0.866 -325.8877 512.7706)" className={styles.st0} width="85" height="231"/>
            </g>
            <g>
              <path className={styles.st0} d="M913,770c0,161.3-174.6,292.1-390,292.1S133,931.3,133,770c-53.1,0-84.2,0-84.2,0
                c0,196.1,212.3,355.2,474.2,355.2s474.2-159,474.2-355.2C997.2,770,966.1,770,913,770z"/>
            </g>
            <g>
              <g>
                <path className={styles.st1} d="M523,864.2C234.2,864.2,0,665.8,0,421c0,0,234.2,0,523,0s523,0,523,0C1046,665.8,811.8,864.2,523,864.2z"/>
              </g>
              <g>
                <path className={styles.st2} d="M1013.7,342.5c-0.4-2.9-0.9-5.7-1.4-8.6C974.7,116.9,738.6-23.3,485,20.7c0,0,30.5,175.9,68.2,392.9
                  c0.5,2.9,1,5.7,1.5,8.6L1013.7,342.5z"/>
              </g>
              <g>
                <path className={styles.st3} d="M1046.2,421c0.1-3,0.1-6,0.1-9c0-227.5-215.9-412-482.2-412c0,0,0,184.5,0,412c0,3,0,6,0,9H1046.2z"/>
              </g>
            </g>
          </svg>
        </div>
      </>
    </div>
  )
}

export default Bassinet